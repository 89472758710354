.mainContent{
    display: flex;
    flex-direction: column-reverse;
}

@media screen and (min-width: 60rem){
    .mainContent{
        flex-direction: row;
        min-height: 90rem;
        overflow: hidden;
    }
}