.header{
    //height: 140px;
    background-color: #334458;
    text-align: left;
    padding-left: 4rem;
    padding-bottom: 1rem;
}

h1{
    color: #fd5d69;
    font-size: 4rem ;
    margin: 0px;
}

h2{
    color: #fff;
    font-size: 2rem;
    margin: 0px;
}

@media screen and (max-width: 630px){
    h1{
        font-size: 3rem;

    }
    h2{
        font-size: 1.8rem;
    }
}

@media screen and (max-width: 500px){
    h1{
        font-size: 2.5rem;

    }
    h2{
        font-size: 1.5rem;
    }
}