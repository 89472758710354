.skillBar{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //align-items: center;
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.skillBar h4{
    font-size: 1.4rem;
    white-space: nowrap;
    width: 50%;
    text-align: left;
    margin-bottom: 0.2rem;
}

.bar{
    width: 65%;
    height: 0.5rem;
    position: relative;
}

.barBackground{
    position: absolute;
    top: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 200px;
}

.barForeground{
    position:absolute;
    top: 0;
    left: 0;
    background-color: #fd5d69;
    border-radius: 200px;
    height: 100%;
    z-index: 100;
}