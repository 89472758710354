.sectionHeader{
    display: flex;
    padding: 0px 2rem;
}

.sectionHeader h3{
    text-transform: uppercase;
    font-size: 1.75rem;
    margin-left: 1rem;

}

.sectionHeader__divider{
    size: 20;
    align-self: center;
    color: white;
    margin: 0px 2rem;
    margin-bottom: 1rem;
}