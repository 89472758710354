.leftSideContent{
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: left;
    padding-top: 2rem;
}

.itemBlock{
    margin: 0px 0px 1rem 0px;
}

.itemBlock h4 {
    white-space: normal;
}

.itemBlock h4 span {
    white-space: nowrap;
}

h3{
    font-size: 1.8rem;
    margin: 0px 0px 1rem 0px;
}

h4{
    font-size: 1.4rem;
    margin: 0px;
}

.lightFont{
    font-weight: 100;
    font-size: 1rem;
}

h5{
    margin: 0px;
    font-size: 1rem;
    font-weight: 500;
}

ol{
    margin: .4rem 0px;
}

@media screen and (min-width: 60rem){
    .leftSideContent{
        flex-grow: 3;
    }
}