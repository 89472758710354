body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // To make it look like a A4 sheet
  min-height: 90rem;
  background-color: #fff;
  width: 100%;
}

body ::after, ::before{
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (min-width: 80rem){
  // To make it look like a A4 sheet
  body{
    width: 80rem;
    min-height: 90rem;
    background-color: #fff;
    margin: 6rem 0;
    -webkit-box-shadow: 1rem 2rem 3rem rgba(102,102,102,.3);
    -moz-box-shadow: 1rem 2rem 3rem rgba(102,102,102,.3);
    box-shadow: 1rem 2rem 3rem rgba(102,102,102,.3);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
