.rightSideContent{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: #959595;
    color: #fff;
}

.avatar{
    height: 17.5rem;
    border-radius: 360px;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
}

.rightSideContent__itemContainer{

}

@media screen and (min-width: 45rem) and (max-width: 60rem) {
    .rightSideContent{
        flex-direction: row;
    }
    .rightSideContent__itemContainer:nth-child(1){
        width: 100%;
    }
    .rightSideContent__itemContainer{
        width: 50%;
    }

}

@media screen and (min-width: 60rem){
    .rightSideContent{
        flex-grow: 3;
        flex-wrap: nowrap;
        //max-width: 365px;
    }
}