.infoSection{
    display: flex;
    padding: 0px 2rem;
}

.infoSection img{

}
.iconContainer{
    width: 40px;
}

.infoSection p{
    margin: 0px;
    font-size: 1.75rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    text-align: left;
}