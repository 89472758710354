.App {
  text-align: center;
  display: flex;
  flex-direction: column;
} 

//Media Queries
@media screen and (min-width: 80rem){
  .app{
    flex-direction: row;
  }
}