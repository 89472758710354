.langaugeBar{
    display: flex;
    justify-content: space-between;
    //align-items: center;
    padding: 0px 2rem;
    margin-bottom: 1rem;
}

.langaugeProficiencyBar{
    width: 100%;
}

.langaugeProficiencyLevel{
    display: flex;
    justify-content: space-between;
}

.langaugeBar h4{
    display: inline;
    font-size: 1.4rem;
    white-space: break;
    text-align: left;
    margin-bottom: 0.2rem;
}

.langaugeProficiencyLevel h5{
    display: inline;
    text-align: right;
    text-transform: uppercase;
    margin: 0px;
}

.bar{
    width: 100%;
    height: 0.5rem;
    position: relative;
}

.barBackground{
    position: absolute;
    top: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 200px;
}

.barForeground{
    position:absolute;
    top: 0;
    left: 0;
    background-color: #fd5d69;
    border-radius: 200px;
    height: 100%;
    z-index: 100;
}